<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="使用返利明细" @closed="handleClosed">
    <template v-if="form">
      <div class="h c">
        <div class="flex">
          <form-info-item label-position="right" label-suffix=":" label="发货单号">{{form.formCode}}</form-info-item>
          <form-info-item label-position="right" label-suffix=":" label="收货地址">{{form.recProvince}}{{form.recCity}}{{form.recDistrict}}{{form.recAddress}}</form-info-item>
        </div>
        <template v-if="rebate">
          <div class="money-count-item">
            <div class="fc-g">已使用返利</div>
            <counter class="num" :end-val="rebate.usedRebate / 100" :decimals="2" :duration="1000" />
          </div>
          <div class="money-count-item">
            <div class="fc-g">可使用返利</div>
            <counter class="num" :end-val="rebate.maxCanUseRebate / 100" :decimals="2" :duration="1000" />
          </div>
        </template>
      </div>
      <el-table border row-key="id" :data="store" highlight-current-row empty-text="没有使用返利记录" style="margin: 8px 0;">
        <el-table-column prop="money" label="使用返利金额" width="120" :formatter="$price" />
        <el-table-column label="使用日期" width="150" :formatter="r => { return new Date(r.createAt).format('yyyy/MM/dd'); }" />
        <el-table-column prop="info" label="备注" />
      </el-table>
      <el-button type="primary" @click="pay" v-if="form.payStatus !== 'pay' && form.payStatus !== 'finish' && rebate && rebate.maxCanUseRebate > 0">使用返利</el-button>
    </template>

    <rebate-form ref="form" @complete="init" />
  </el-dialog>
</template>

<script>
import { getByCode, getMaxRebate, getRebates } from "@/api/consignment";
import counter from "vue-count-to";
import rebateForm from "./rebate-form";

export default {
  components: { counter, rebateForm },
  data() {
    return {
      loading: false,
      dialog: false,
      form: null,
      rebate: null,
      store: []
    };
  },
  methods: {
    handleClosed() {
      this.form = null;
      this.rebate = null;
    },
    init() {
      this.loadMaxRebate();
      this.loadRebates();
    },
    loadMaxRebate() {
      getMaxRebate(this.form).then(res => {
        this.rebate = res;
      });
    },
    loadRebates() {
      this.loading = true;
      getRebates({
        entId: this.form.entId,
        receiverId: this.form.receiverId,
        erpCode: this.form.erpCode
      })
        .then(res => {
          this.store = res;
        })
        .finally(_ => {
          this.loading = false;
        });
    },
    loadDetail(code) {
      this.loading = true;
      getByCode(code)
        .then(res => {
          this.form = res;
          this.init();
        })
        .finally(_ => {
          this.loading = false;
        });
    },
    load(form) {
      if (form && form.formCode) {
        if (form.items) {
          this.form = form;
          this.init();
        } else this.loadDetail(form.formCode);
        this.dialog = true;
      }
    },
    pay() {
      if (this.form && this.rebate) {
        let form = JSON.parse(JSON.stringify(this.form));
        form._maxRebate = this.rebate.maxCanUseRebate;
        form.currentPayRebate = this.rebate.maxCanUseRebate;
        form.payInfo = "";
        this.$refs.form && this.$refs.form.resetForm(form);
      }
    }
  }
};
</script>